<template>
  <div>
    <aad-back></aad-back>
    <aad-title v-if="showMapList">Maps</aad-title>
    <aad-title v-else>{{ thisMap.Name }}</aad-title>

    <aad-card-deck v-if="showMapList">
      <aad-card
        v-for="map in allMaps"
        class="section-card"
        :key="map.id"
        :title="map.Name"
        :imageUrl="map.ImagePath"
        :routeLink="{ name: 'MapsId', params: { id: map.id } }"
        logClick="true"
        logEventName="aad_map_click"
      />
    </aad-card-deck>

    <div v-else>
      <img :src="thisMap.ImagePath" alt="" class="map-image" />
      <!--
      <div>MAP ID: {{ mapId }}</div>
      <div>GET MAP BY ID: {{ thisMap }}</div>
      -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AadCardDeck from "@/components/AadCardDeck.vue";
import AadCard from "@/components/AadCard.vue";

export default {
  data() {
    return {
      mapId: this.$route.params.id,
    };
  },
  metaInfo: {
    title: "Maps",
  },
  components: { AadCardDeck, AadCard },
  mounted() {
    this.getMaps();
  },
  methods: {
    getMaps() {
      this.$store.dispatch("loadMaps");
    },
  },
  computed: {
    ...mapGetters(["allMaps", "getMapById"]),
    showMapList: function () {
      return (
        this.mapId == undefined || this.getMapById(this.mapId) == undefined
      );
    },
    thisMap: function () {
      return this.getMapById(this.mapId);
    },
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new IDs and clearing it...
      this.mapId = this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.map-image {
  max-width: 100%;
}
</style>
